import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { MdClose } from "react-icons/md";
import ChangePassModal from "./ChangePassModal";
import axios from "axios"; // Add axios for API request
import { toast } from "react-toastify"; // Optional, for notifications
import axiosInstance from "../../api/axios";

const SettingsModal = ({ isOpen, setIsOpen, user }) => {
  const [isChangePassOpen, setIsChangePassOpen] = useState(false);
  const [newFirstName, setNewFirstName] = useState(''); // state for first name input
  const [newLastName, setNewLastName] = useState(''); 

  useEffect(() => {
    if (user) {
      setNewFirstName(user.first_name);
      setNewLastName(user.last_name);
    }
  }, [user]);
  console.log(user.first_name);
  if (!isOpen) return null;
  const handleChangePassword = () => {
    setIsChangePassOpen(true);
  };

  const handleUpdateProfile = async () => {
    try {
      // Send the updated first name to the API
      const response = await axiosInstance.post("/newuser/api/update-profile", {
        first_name: newFirstName,
        last_name: newLastName,
      });
      toast("Profile updated successfully"); // Optional success message
      setIsOpen(false); // Close the modal on successful update
    } catch (error) {
      toast.error("Failed to update profile"); // Optional error message
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-xl">
        <div className="flex justify-between items-center border-b border-solid border-slate-200 w-full p-4">
          <h4 className="text-md font-semibold">Settings</h4>
          <button
            className="background-transparent font-bold uppercase p-2 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <MdClose className="w-5 h-5" />
          </button>
        </div>
        <div className="p-6">
          <div className="relative mb-8">
            <button
              className="absolute top-0 right-0 text-sm text-[#8F27C1] font-semibold hover:underline"
              onClick={handleChangePassword}
            >
              Change Password?
            </button>
            <h3 className="text-md font-medium mb-4">Personal Settings</h3>
            <div className="flex items-center mb-6">
              <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center text-2xl font-bold text-gray-700">
                {String(user?.first_name || user?.email).slice(0, 1)}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <TextField
                  label={
                    <span>
                      First Name<span className="text-red-500">*</span>
                    </span>
                  }
                  value={newFirstName} // Bind to the state
                  onChange={(e) => setNewFirstName(e.target.value)} // Update the state on change
                  fullWidth
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  label={
                    <span>
                      Last Name<span className="text-red-500">*</span>
                    </span>
                  }
                  value={newLastName}
                  onChange={(e) => setNewLastName(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </div>
            <div className="mt-4 cursor-not-allowed">
              <TextField
                label="Email"
                value={user?.email}
                fullWidth
                variant="outlined"
                disabled
                sx={{ opacity: 0.8 }}
              />
            </div>
          </div>

          {/* <div className="border-t pt-4">
            <h3 className="text-md font-medium mb-2">Delete Personal Account</h3>
            <p className="text-sm text-gray-600 mb-2">
              Don’t need wecraft any longer? We understand and appreciate you
              using our service!
            </p>
            <button className="text-red-500 text-sm font-medium hover:underline">
              Delete Account
            </button>
          </div> */}

          <div className="flex justify-end mt-6 space-x-4">
            <button
              onClick={() => setIsOpen(false)}
              className="h-[46px] w-[90px] px-4 py-2 text-sm font-bold text-black bg-white border border-black rounded-lg hover:bg-gray-200 hover:border-none"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleUpdateProfile} // Trigger the profile update
              className="classic-button h-[46px] w-[90px] text-[18px] font-[600]"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      {/* Change Pass Modal */}
      <ChangePassModal isOpen={isChangePassOpen} setIsOpen={setIsChangePassOpen} />
    </div>
  );
};

export default SettingsModal;
